<template>
  <table
    v-if="$store.state.window.width > 1570"
  >
    <thead>
      <tr>
        <th>
          {{ $t('views.default.transaction.purchases.purchase-date') }}
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.payment-amount') }}
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.start-end') }}
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.total') }} %
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.payment-period') }}
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.income') }}
        </th>

        <th>
          {{ $t('views.default.transaction.purchases.next-payout') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-if="data.length > 0">
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td class="c1">
            <p>
              {{ item.boxes ? 'Onlix Box' : $t('views.default.transaction.purchases.container') }} {{ item.container?.name || '' }}
            </p>

            <div class="c1__flex">
              <p>
                {{ $format.date(item.created_at) }}
              </p>

              <p>
                {{ item.container?.declaration || '' }}
              </p>
            </div>
          </td>

          <td class="c2">
            <p>
              $ {{ $format.wallet(item.amount, ',') }}
            </p>

            <div class="c2__balance">
              {{ $t(`views.default.transaction.purchases.from-${item.payment_from}`) }}
            </div>
          </td>

          <td class="c3">
            {{ $format.date(item.start_at) }} - {{ $format.date(item.end_at) }}
          </td>

          <td class="c4">
            {{ item.annual_percent }}%
          </td>

          <td class="c5">
            {{ $t(`views.default.transaction.purchases.once-${item.period_unit}`, { n: item.period_value }) }}
          </td>

          <td class="c6">
            $ {{ $format.wallet(item.once_reward, ',') }}
          </td>

          <td class="c7">
            {{ $format.date(item.next_at) }}
          </td>
        </tr>
      </template>

      <tr
        v-else
        class="none"
      >
        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>
      </tr>
    </tbody>
  </table>

  <div
    v-else
    class="list"
  >
    <template v-if="data.length > 0">
      <div
        v-for="item in data"
        :key="item.id"
        class="list__item"
      >
        <div class="list__item__row">
          <p class="small-text label">
            {{ $format.date(item.created_at) }}
          </p>

          <p class="little-text content">
            {{ item.container?.declaration || '' }}
          </p>
        </div>

        <div class="list__item__row name">
          <div class="name">
            {{ item.boxes ? 'Onlix Box' : $t('views.default.transaction.purchases.container') }} {{ item.container?.name || '' }}
          </div>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.payment-amount') }}
          </p>

          <p class="small-text content">
            $ {{ $format.wallet(item.amount, ',') }}
          </p>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.start-end') }}
          </p>

          <p class="small-text content">
            {{ $format.date(item.start_at) }} - {{ $format.date(item.end_at) }}
          </p>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.total') }} %
          </p>

          <p class="small-text content">
            {{ item.annual_percent }}%
          </p>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.payment-period') }}
          </p>

          <p class="small-text content">
            {{ $t(`views.default.transaction.purchases.once-${item.period_unit}`, { n: item.period_value }) }}
          </p>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.next-payout') }}
          </p>

          <p class="small-text content">
            {{ $format.date(item.next_at) }}
          </p>
        </div>

        <div class="list__item__row">
          <p class="little-text label">
            {{ $t('views.default.transaction.purchases.income') }} $
          </p>

          <p class="content count">
            $ {{ $format.wallet(item.once_reward, ',') }}
          </p>
        </div>
      </div>
    </template>

    <div
      v-else
      class="list__item none"
    >
      ---
    </div>
  </div>

  <div
    v-if="data.length < count"
    class="controls"
  >
    <Pagination
      :name="$route.name"
      :pages="_countItemPagination"
      :load-more="data.length < count"
      :loading="isFetchMore"
      @loadMore="fetchMore()"
    />
  </div>
</template>

<script>
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      mountFetch: false,
      data: [],
      count: 0,
      length: 10,
      isFetchMore: false
    }
  },
  computed: {
    _offset () {
      return ((this.$route.params.page ? this.$route.params.page : 1) * (this.length)) - (this.length)
    },
    _countItemPagination () {
      if (this.count) {
        return Math.ceil(this.count / (this.length))
      }

      return 0
    },
    date () {
      return this.$attrs.date
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch(false)
    }
  },
  deactivated () {
    if (this.data.length > this.length) {
      this.data = this.data.slice(0, this.length + 1)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        isCacheFetch && this.$emit('preloader', true)

        const resp = await this.$axios.get(
          `/private/purchases?limit=${this.length}&offset=${this._offset}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`)

        if (resp.status === 200) {
          this.count = resp.data.count
          this.data = resp.data.data

          if (this.$route.params.page > 1 && this.data.length === 0) {
            this.$store.commit('SET_ERROR', true)
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        isCacheFetch && this.$emit('preloader', false)
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this._offset + (this.data.length ? this.data.length : 0)
      const link = `/private/purchases?offset=${offset}&limit=${this.length}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.data.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      if (this.$route.params.page) {
        this.fetch()
      }
    },
    date () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-spacing: 0;

  thead {
    tr {
      th {
        padding: 0 20px 20px 20px;
        color: rgb(var(--text-color-light-gray));
        text-align: left;
        font-size: 12px;
        line-height: 100%;
        font-family: Medium;
      }
    }
  }

  tbody {
    tr {
      &.none {
        font-size: 18px;
        line-height: 100%;
        color: #9CA3B8;
      }

      td {
        padding: 17px 20px;

        &.c1 {
          p {
            color: rgb(var(--color-dark));
            font-family: SemiBold;
            font-size: 16px;
            line-height: 100%;
          }

          .c1__flex {
            margin-top: 8px;
            display: flex;

            p {
              color: rgba(var(--text-color-light-gray));
              font-family: Medium;
              font-size: 12px;
              line-height: 100%;

              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }

        &.c2 {
          p {
            color: rgb(var(--color-dark));
            font-family: Medium;
            font-size: 14px;
            line-height: 100%;
          }

          .c2__balance {
            margin-top: 8px;
            color: rgba(var(--text-color-light-gray));
            font-family: Medium;
            font-size: 12px;
            line-height: 100%;
          }
        }

        &.c3, &.c4, &.c5, &.c6, &.c7 {
          color: rgb(var(--color-dark));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;
        }
      }

      &:nth-child(odd) {
        td {
          background: rgba(240, 242, 248, .5);

          &:first-child {
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
}

.list {
  width: 100%;

  .list__item {
    width: 100%;
    padding: 10px;
    border-radius: 10px;

    &.none {
      font-size: 18px;
      line-height: 100%;
      color: #9CA3B8;
    }

    &:nth-child(odd) {
      background: rgba(240, 242, 248, .5);
    }

    .list__item__row {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.name {
        height: auto;
      }

      .name {
        margin: 10px 0 20px;
        // padding: 15px 0 20px;
        display: block;
        font-size: 18px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      .label {
        // max-width: 105px;
        font-size: 12px;
        line-height: 100%;
        color: #787C8D;
      }

      .content {
        line-height: 120%;
        text-align: right;
        color: #202539;
        font-family: Medium;

        &.little-text {
          color: #787C8D;
        }

        &.count {
          font-size: 18px;
          font-family: SemiBold;
        }
      }
    }
  }
}

.controls {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #9CA3B8;
}
</style>
